import { Button, Col, Row } from "antd";
import React, { useCallback, useEffect } from "react";

const DealerportalLoginPage: React.FC<Props> = (props) => {
  const url = process.env.REACT_APP_DEALER_PORTAL_SSO_URL;
  return (
    <Row className="login"  align="middle" justify="center">
      <Col xs={{ span: 22, offset: 1 }} sm={{ span: 8}}>
        <h1>Dealer portal access</h1>
        <Button
          type="primary"
          className="login-form-button"
          onClick={() => { window.location.href = url; } }
        >
          Login with Dealer Portal
        </Button>
      </Col>
    </Row>
  );
};

export default DealerportalLoginPage;
