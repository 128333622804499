import * as actions from "../../../actions";

import { AjaxResponse, ajax } from "rxjs/ajax";
import { AutoComplete, Input } from "antd";
import { Observable, of } from 'rxjs';
import React, { useCallback, useEffect, useState } from "react";
import { catchError, switchMap } from 'rxjs/operators';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { AuthState } from "../../../reducers/auth";
import { FiltersState } from "../../../reducers/filters";
import { LanguageState } from "../../../reducers/language";
import { Loader } from "../../loader";
import Markets from "./markets";
import { State } from "../../../interfaces";
import Typologies from "./typologies";
import domain from "../../../utils/domain";

const Search = Input.Search;

interface Option {
  value: string;
  label: string;
}

interface Props { }

const SearchPanel: React.FC<Props> = (props) => {
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { accessToken, sessionToken } = auth;
  const { selectedBrand, isLoading, searchString } = filters;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );

  const { labels } = language;

  const fetchOptions = (inputValue: String): Observable<Option[]> => {
    setOpenOptions(true);
    if (!inputValue) {
      return of([]);
    }
    return ajax({
      crossDomain: true,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-CSRF-Token": sessionToken,
      },
      url: `${domain}search_api_autocomplete/search_suggestions?q=${inputValue}&brand=${[selectedBrand?.id]}`,
      body: {},
    }).pipe(
      switchMap((response: AjaxResponse) => {
        const options: Option[] = response.response.map((item: any) => ({
          value: item.value,
        }));
        return of(options);
      }),
      catchError((error) => {
        console.error('Error fetching options:', error);
        return of([]);
      })
    );
  };

  const [options, setOptions] = useState<Option[]>([]);
  const [openOptions, setOpenOptions] = useState(false);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>(searchString.text || "");

  const historyOptions = Object.values(searchHistory).map((key) => ({ value: key }));

  const handleChange = async (value: string) => {
    const newInputValue = value;
    setInputValue(value);

    if (!newInputValue) {
      setOpenOptions(true);
      setOptions([]);
      return;
    }

    try {
      const fetchedOptions = await fetchOptions(newInputValue).toPromise();
      setOptions(fetchedOptions);
    } catch (error) {
      console.error('Error fetching options:', error);
      setOptions([]);
    }
  };

  const dispatch = useDispatch();

  const onSetSearchString = useCallback(
    (text: string) => {
      const trimmedText = text?.trim();
      dispatch(actions.setSearchString(trimmedText));
      setInputValue(trimmedText);
      if (trimmedText && !searchHistory.includes(text?.trim())) {
        const updatedHistory = [trimmedText, ...searchHistory];
        const limitedHistory = updatedHistory.slice(0, 5);
        setSearchHistory(limitedHistory);
        localStorage.setItem('searchHistory', JSON.stringify(limitedHistory));
      }
    },
    [dispatch, searchHistory]
  );

  useEffect(() => {
    const storedHistory = JSON.parse(localStorage.getItem('searchHistory') || 'null');
    if (storedHistory && Array.isArray(storedHistory)) {
      setSearchHistory(storedHistory);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }, [searchHistory]);

  const onTextSearch = (value: string) => {
    setOpenOptions(false);
    onSetSearchString(value);
  };

  const unparsedUserMarkets = localStorage.getItem("markets");
  const userMarkets = unparsedUserMarkets ? JSON.parse(unparsedUserMarkets) : {};
  const marketsEnabledForSearch = ["united kingdom", "ireland", "germany", "austria", "switzerland", "benelux ned"];
  const finalMarkets = Object.values(userMarkets).filter((market) => {
    let m = market?.name?.toLowerCase()
    if (!m) {
      return false;
    }
    return marketsEnabledForSearch.includes(m);
  });
  // {language?.selectedLanguage?.code === "fr" && (
  return (
    <>
      {!isLoading && labels && (
        <div className="search-wrapper">
          {language?.selectedLanguage?.code &&
            finalMarkets && finalMarkets.length > 0 && (
              <AutoComplete
                className="autocomplete-panel-element"
                options={inputValue !== "" ? options : historyOptions}
                onSelect={onTextSearch}
                onSearch={handleChange}
                open={openOptions}
                onBlur={() => setOpenOptions(false)}
                onFocus={() => setOpenOptions(true)}
                onClear={() => {
                  setInputValue("");
                  setOpenOptions(true);
                }}
              >
                {/* 
                  Search Input is added to 
                  allow keyboard enter button to submit 
                */}
                <Search
                  placeholder={labels.searchText}
                  defaultValue={inputValue}
                  allowClear
                  value={inputValue}
                  onSearch={onTextSearch}
                />
              </AutoComplete>
            )}
          {labels && !isLoading && (
            <>
              <Markets labels={labels} />
              <Typologies labels={labels} />
              {/* <Categories labels={labels} /> */}
              {/* <Tags labels={labels} /> */}
              {/* <Properties labels={labels} /> */}
            </>
          )}
        </div>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default SearchPanel;
