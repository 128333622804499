import React, { useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Checkbox, Form, Input, Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import * as actions from "../../actions";
import { Loader } from "../../components/loader";
import { AuthState } from "../../reducers/auth";
import { LanguageState } from "../../reducers/language";
import { State } from "../../interfaces";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 }
};

interface Props {
  location: any;
}

const LoginPage: React.FC<Props> = (props) => {
  const { location } = props;

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { isFetching, rememberUser } = auth;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;
  console.log("labels", labels);

  const dispatch = useDispatch();

  const onLogin = useCallback(
    (values, from) => dispatch(actions.login(values, from)),
    [dispatch]
  );

  const onFinish = (values: any) => {
    // console.log("Success:", values);
    const from = location.state ? location.state.from.pathname : null;
    onLogin(values, from);
  };

  const localAuth = localStorage.getItem("auth");
  const isLoading = localAuth !== null;

  return (
    <>
      {!isLoading && (
        <Row className="login" align="middle">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 8, offset: 8 }}>
            {labels && (
              <Form
                {...layout}
                className="login-form"
                initialValues={{ remember: rememberUser }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: `${labels.requiredFiels}`
                    }
                  ]}
                >
                  <Input
                    prefix={<UserOutlined style={{ fontSize: 13 }} />}
                    placeholder={labels.username}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: `${labels.requiredFiels}`
                    }
                  ]}
                >
                  <Input
                    prefix={<LockOutlined style={{ fontSize: 13 }} />}
                    type="password"
                    placeholder={labels.password}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="wrapper">
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox className="remember">
                        {labels.rememberMe}
                      </Checkbox>
                    </Form.Item>

                    <Link className="login-form-forgot" to="/forgot-password">
                      {labels.forgotPassword}
                    </Link>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isFetching}
                    // disabled={hasErrors(getFieldsError())}
                  >
                    {labels.login}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default LoginPage;
