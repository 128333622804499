import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Divider, Tag, Tooltip } from "antd";
import { useMedia } from "react-use";
import * as actions from "../../../../actions";

// import { useWhyDidYouUpdate } from "../../../hooks/useWhyDidYouUpdate";

import { FiltersState } from "../../../../reducers/filters";
import { State, Dictionary } from "../../../../interfaces";
import { detectIE11 } from "../../../../utils";
import DynamicIcon from "../../../DynamicIcon";

const { CheckableTag } = Tag;

interface Props {
  labels?: Dictionary<string>;
}

let Typologies: React.FC<Props> = props => {
  const { labels } = props;

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const values = filters.typologies?.entities;
  let tmpTypologies = filters.typologies?.selectedTypologies;
  if(!tmpTypologies){
    tmpTypologies = [];
  } else if(Array.isArray(tmpTypologies)){
    tmpTypologies = tmpTypologies.map((typology: any) => {
      if(typeof typology === "object" && typology?.id){
        return typology.id;
      }
      return typology;
    });
  } else if(typeof tmpTypologies === "object" && tmpTypologies?.id){
    tmpTypologies = [tmpTypologies.id];
  }
  const selectedTypologies = tmpTypologies;

  const hasHover = useMedia("(hover: hover)");

  const dispatch = useDispatch();

  const toggle = (id: string) => {
    dispatch(actions.toggleTypologyOnSelectedList(id));
  };

  const _onReset = () => {
    dispatch(actions.setTypology([]));
  };

  const reset = selectedTypologies.length ? (
    <CloseCircleOutlined onClick={_onReset} />
  ) : null;

  // Internet Explorer 11
  const isIE = detectIE11();
  return (
    <>
      <Divider className="filters-label" orientation="left">
        {labels?.typologies} {reset}
      </Divider>
      <div className="typology-wrapper search-panel-element">
        {values.all.map((_id: string) => {
          const { id, name, icon } = values.byId[_id];
          return hasHover || isIE ? (
            <Tooltip
              key={id}
              title={name}
              placement="topLeft"
              arrowPointAtCenter
              mouseLeaveDelay={0}
              getPopupContainer={triggerNode =>
                triggerNode.parentNode as HTMLElement
              }
            >
              <CheckableTag
                className="typology-tag"
                checked={selectedTypologies.indexOf(id) > -1 || selectedTypologies[0] == id}
                onChange={checked => toggle(id)}
              >
                <DynamicIcon type={icon} />
              </CheckableTag>
            </Tooltip>
          ) : (
            <CheckableTag
              key={id}
              className="typology-tag"
              checked={selectedTypologies.indexOf(id) > -1 || selectedTypologies[0] == id}
              onChange={checked => toggle(id)}
            >
              <DynamicIcon type={icon} />
            </CheckableTag>
          );
        })}
      </div>
    </>
  );
};

export default Typologies;
