import { of } from "rxjs";
import * as actions from "../actions";
import { combineEpics } from "redux-observable";
import {
  fetchItemsEpic,
  fetchNextPageEpic,
  setFilterForSearchEpic,
  resetPageForItemsEpic,
  fetchItemByIdEpic,
  removeItemByIdEpic,
  editTagsForItemEpic,
  fetchItemsAfterEditEpic,
  fetchItemsForHomeEpic,
  typologyEpic
} from "./items";

import {
  sessionTokenEpic,
  autoLoginEpic,
  loginEpic,
  loginWithTokenEpic,
  loginSuccessEpic,
  getUserEpic,
  refreshTokenEpic,
  logoutEpic,
  recoverPasswordEpic,
  unauthorizedUserEpic
} from "./auth";

import { setBrandEpic, getFiltersEpic, initFiltersEpic } from "./filters";
import {
  getLanguagesEpic,
  setBrowserLanguageEpic,
  setLanguageEpic
} from "./language";
import { getSharedLinksEpic } from "./share";
import { getSharedLibraryLinkEpic } from "./shareLibrary";
import {
  getNewsEpic,
  setFilterForNewsEpic,
  resetPageForNewsEpic,
  fetchNextPageForNewsEpic,
  fetchNewsyIdEpic
} from "./news";
import { getLatestForumsEpic } from "./forums";
import { getHomeEpic } from "./home";
import { routerEpic } from "./router";
import { setInitFilters } from "../utils/filters";

export function initEpic() {
  // get browser language
  let locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    "en-US";

  // prendo i primi 2 caratteri della lingua
  const language = locale.substr(0, 2);

  //setInitFilters();

  return of(
    actions.setBrowserLanguage(language),
    actions.getLanguages(),
    actions.getSessionToken(),
    actions.initFilters()
  );
}

export const rootEpic = combineEpics(
  initEpic,
  getLanguagesEpic,
  setBrowserLanguageEpic,
  setLanguageEpic,
  sessionTokenEpic,
  autoLoginEpic,
  loginEpic,
  loginWithTokenEpic,
  loginSuccessEpic,
  getUserEpic,
  refreshTokenEpic,
  logoutEpic,
  recoverPasswordEpic,
  unauthorizedUserEpic,
  //initFiltersEpic,
  setBrandEpic,
  getFiltersEpic,
  fetchItemsEpic,
  fetchItemsForHomeEpic,
  fetchNextPageEpic,
  setFilterForSearchEpic,
  resetPageForItemsEpic,
  fetchItemByIdEpic,
  removeItemByIdEpic,
  editTagsForItemEpic,
  fetchItemsAfterEditEpic,
  getSharedLinksEpic,
  getSharedLibraryLinkEpic,
  getNewsEpic,
  // setFilterForNewsEpic,
  // resetPageForNewsEpic,
  // fetchNextPageForNewsEpic,
  // fetchNewsyIdEpic,
  // getLatestForumsEpic,
  typologyEpic,
  getHomeEpic,
  routerEpic
);
