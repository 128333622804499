import { Epic } from "redux-observable";
import { filter, switchMap } from "rxjs/operators";
import { State } from "../interfaces";
import { isOfType } from "typesafe-actions";
import * as actionTypes from "../constants/actionTypes";
import { history } from "../stores/configureStore";
import { createLibraryQueryString } from "../utils";

export const routerEpic: Epic<any, any, State> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST,
        actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST,
        actionTypes.SET_MARKET,
        actionTypes.SET_TYPOLOGY,
        actionTypes.RESET_PAGE_FOR_ITEMS,
        actionTypes.SELECT_LANGUAGE,
        actionTypes.SEARCH_ITEMS_SUCCESS
      ])
    ),
    switchMap((action) => {
      const isLibrary = history.location.pathname.includes("/library");
      if (!isLibrary) return [];

      const qs = createLibraryQueryString(state$.value.filters, history);
     
      let _search = history.location.search;
      if(_search.charAt(0)!= "?"){
        _search = "?" + _search;
      }

      let _qs = qs;
      if(_qs.charAt(0)!= "?"){
        _qs = "?" + _qs;
      }

      if (_search !== _qs) {
        history.replace({
          pathname: history.location.pathname,
          search: qs
        });
      }

      return [];
    })
  );
