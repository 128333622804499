import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action } from "../actions/auth";
import { Brand } from "../interfaces";

export interface User {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  markets: string[];
  role: string[];
  portal: boolean;
  brands: Brand[];
}

export type AuthState = ReturnType<typeof auth>;

const sessionToken = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_SESSION_TOKEN_SUCCESS:
      // return "orFIjH4AMBWUbHmFaAVEG1zHy32_U1pq943ILQ_cMCM";
      return action.payload.token;

    case actionTypes.GET_SESSION_TOKEN_ERROR:
    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.TOKEN_EXPIRED:
      return null;

    default:
      return state;
  }
};

const accessToken = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return action.payload.access_token;

    case actionTypes.LOGIN:
    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.TOKEN_EXPIRED:
      return null;

    default:
      return state;
  }
};

const refreshToken = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return action.payload.refresh_token;

    case actionTypes.LOGIN:
    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.TOKEN_EXPIRED:
      return null;

    default:
      return state;
  }
};

const expires_in = (state: number = 0, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return action.payload.expires_in;

    case actionTypes.LOGIN:
    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.TOKEN_EXPIRED:
      return 0;

    default:
      return state;
  }
};

const forgotPasswordMessage = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.LOGIN_ERROR:
    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.LOGOUT_ERROR:
    case actionTypes.RECOVER_PASSWORD:
    case actionTypes.RECOVER_PASSWORD_ERROR:
      return false;

    case actionTypes.RECOVER_PASSWORD_SUCCESS:
      return true;

    default:
      return state;
  }
};

const isFetching = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
    case actionTypes.RECOVER_PASSWORD:
      // case actionTypes.LOGOUT:
      return true;

    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.LOGIN_ERROR:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.LOGOUT_ERROR:
    case actionTypes.RECOVER_PASSWORD_SUCCESS:
    case actionTypes.RECOVER_PASSWORD_ERROR:
      return false;

    default:
      return state;
  }
};

const hasError = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.RECOVER_PASSWORD:
    case actionTypes.RECOVER_PASSWORD_SUCCESS:
      return false;

    case actionTypes.LOGIN_ERROR:
    case actionTypes.LOGOUT_ERROR:
    case actionTypes.GET_USER_ERROR:
    case actionTypes.RECOVER_PASSWORD_ERROR:
      return true;

    default:
      return state;
  }
};

const errorMessage = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.LOGOUT:
    case actionTypes.RECOVER_PASSWORD_SUCCESS:
    case actionTypes.RECOVER_PASSWORD:
      return null;

    case actionTypes.LOGIN_ERROR:
    case actionTypes.LOGOUT_ERROR:
    case actionTypes.RECOVER_PASSWORD_ERROR:
    case actionTypes.GET_USER_ERROR:
      return action.payload.error;

    default:
      return state;
  }
};

const rememberUser = (state: boolean = true, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      const { values } = action.payload;
      return values.remember;

    default:
      return state;
  }
};

const user = (state: User | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.GET_USER_SUCCESS:
      const { user } = action.payload;

      const body = document.getElementsByTagName("BODY")[0];
      body.classList.add("logged-in");

      return user;

    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
      const _body = document.getElementsByTagName("BODY")[0];
      _body.classList.remove("logged-in");
      return null;

    default:
      return state;
  }
};

const from = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
    case actionTypes.LOGIN_WITH_TOKEN:
      const { from } = action.payload;
      return from;

    case actionTypes.LOGOUT:
    case actionTypes.LOGOUT_SUCCESS:
      return null;

    default:
      return state;
  }
};

const auth = combineReducers({
  sessionToken,
  accessToken,
  refreshToken,
  expires_in,
  rememberUser,
  from,
  isFetching,
  user,
  hasError,
  errorMessage,
  forgotPasswordMessage
});

export default auth;
