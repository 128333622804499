import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import * as actions from "../../actions";
import { Loader } from "../../components/loader";

interface Props {}

const LogoutPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("logout");
    dispatch(actions.logout());
  }, [dispatch]);

  return (
    <>
      <Loader isLoading={true} />
    </>
  );
};

export default LogoutPage;
