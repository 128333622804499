import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";
import { Action } from "../actions/language";
import { Action as FilterAction } from "../actions/filters";
import { Action as NewsAction } from "../actions/news";
import { Action as ForumsAction } from "../actions/forums";
import { Action as HomeAction } from "../actions/home";
import { Epic } from "redux-observable";
import { isOfType } from "typesafe-actions";
import { of, concat } from "rxjs";
import { ajax } from "rxjs/ajax";
import { switchMap, mergeMap, map, catchError, filter } from "rxjs/operators";
import { normalizeLanguages } from "../utils/norm";
// import { push } from "connected-react-router";
import domain from "../utils/domain";
import { State } from "../interfaces";
import { history } from "../stores/configureStore";

export const getLanguagesEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_LANGUAGES)),
    switchMap((action) => {
      // uso forkJoin per chiamare più servizi in sequenza
      return ajax({
        crossDomain: true,
        url: `${domain}api/util/languages?_format=json`
      }).pipe(
        map((results) => {
          const languages = results.response.languages;

          const payload = normalizeLanguages(languages);
          return actions.getLanguagesSuccess(payload);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getLanguagesError(error.response.message, error.status)
            );
          return of(actions.getLanguagesError(error.message, error.status));
        })
      );
    })
  );

export const setBrowserLanguageEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_LANGUAGES_SUCCESS)),
    mergeMap((action) => {
      const state = state$.value.language;
      const { entities, browserLanguageId } = state;
      const language =
        entities.byId[browserLanguageId as string] ||
        entities.byId[entities.all[0]];
      return of(actions.selectLanguage(language));
    })
  );

export type setLanguageEpicAction =
  | Action
  | FilterAction
  | NewsAction
  | ForumsAction
  | HomeAction;
export const setLanguageEpic: Epic<
  setLanguageEpicAction,
  setLanguageEpicAction,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.SELECT_LANGUAGE)),
    switchMap((action) => {
      const state = state$.value.auth;
      const { user } = state;
      const { language } = action.payload;

      return ajax({
        crossDomain: true,
        url: `/locale/${language.id}.json`
      }).pipe(
        mergeMap((results) => {
          const labels = results.response;
          if (user) {
            // if the user is logged in, we reset all the states and go to the home page, we use of to dispach multiple actions

            const _actions: setLanguageEpicAction[] = [
              actions.getLocaleSuccess(labels),
              // push("/home"),
              actions.getFilters()
            ];

            const { filters } = state$.value;
            const { selectedBrand } = filters;
            console.log("selectedBrand", selectedBrand, history.location);

            // controllo se la brand è selezionata prima di chiamare news e forums
            if (selectedBrand) {
              _actions.push(actions.getHomeBanners());
              _actions.push(actions.getNews());
              _actions.push(actions.getForums());
            }
            return concat(_actions);
          } else {
            return of(actions.getLocaleSuccess(labels));
          }
        })
      );
    })
  );