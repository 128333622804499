import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";
import { Action as FilterAction } from "../actions/filters";
import { Action as NewsAction } from "../actions/news";
import { Action as ForumsAction } from "../actions/forums";
import { Epic } from "redux-observable";
import { isOfType } from "typesafe-actions";
import { of, forkJoin } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, map, catchError, filter } from "rxjs/operators";
import {
  normalizeFlatList,
  normalizeTreeData,
  normalizeTreeDataAll
} from "../utils/norm";
import domain from "../utils/domain";
import { State, CategoryEntities } from "../interfaces";
import { findSubtypeById } from "../utils";
import language from "../reducers/language";

export type Action = FilterAction | NewsAction | ForumsAction;
export const setBrandEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.SET_BRAND)),
    mergeMap((action) => {
      return of(actions.getFilters(), actions.getNews());
    })
  );

export const initFiltersEpic: Epic<Action, Action, State> = (action$, state$) => {
  action$.pipe(
    filter(isOfType(actionTypes.INIT_FILTERS)),
    mergeMap((action) => {
      return of(
        actions.initFilters()
      )
    })
  );
}

const fetchMarkets = () => (action: any, state: State) => {
  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;

  return ajax({
    crossDomain: true,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_markets?_format=json&lang=${language.selectedLanguage?.id
      }&brands=${[selectedBrand?.id]}`
  });
};

const getSelectedMarkets = (state: State) => {
  const { filters } = state;
  const markets = filters.markets.selectedMarkets;
  const _markets = markets.length > 0 ? markets : "";
  // console.log("markets ", _markets);
  return _markets;
};

const fetchTypes = () => (action: any, state: State) => {
  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;
  let markets = getSelectedMarkets(state);
  let ls = localStorage.getItem("initFilters");
  if (ls) {
    const initFilters = JSON.parse(ls);
    if (initFilters.market.length) {
      markets = initFilters.market;
    }
  }
  return ajax({
    crossDomain: true,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_types?_format=json&lang=${language.selectedLanguage?.id
      }&brands=${[selectedBrand?.id]}&market=${markets}&multilevel=1`
  });
};

const fetchCategories = () => (action: any, state: State) => {
  /*
      categorie
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */
  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;

  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_categories?_format=json&lang=${language.selectedLanguage?.id
      }&brands=${[selectedBrand?.id]}&market=${getSelectedMarkets(state)}`
  });
};

const fetchTags = () => (action: any, state: State) => {
  /*
      tags
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */

  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;
  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_tags?_format=json&lang=${language.selectedLanguage?.id
      }&brands=${[selectedBrand?.id]}&market=${getSelectedMarkets(
        state
      )}&all=${true}`
  });
};

const fetchProperties = () => (action: any, state: State) => {
  /*
      tags
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */

  const { auth, language, filters } = state;
  const { selectedBrand } = filters;
  const { accessToken } = auth;
  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_properties?_format=json&lang=${language.selectedLanguage?.id
      }&brands=${[selectedBrand?.id]}&market=${getSelectedMarkets(state)}`
  });
};

export const getFiltersEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.GET_FILTERS,
        actionTypes.SET_MARKET,
        actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST
      ])
    ),
    mergeMap((action) => {
      // uso forkJoin per chiamare più servizi in sequenza
      return forkJoin(
        fetchMarkets()(action, state$.value),
        fetchTypes()(action, state$.value),
        fetchCategories()(action, state$.value),
        fetchTags()(action, state$.value),
        fetchProperties()(action, state$.value)
      ).pipe(
        mergeMap((results) => {
          // forkJoin ritorna un array con i risultati delle chiamate precedenti
          const [markets, typologies, categories, tags, properties] = results;
          const _typologies = normalizeFlatList(typologies.response.item_type);
          const _markets = normalizeFlatList(markets.response.market);
          const _categories: CategoryEntities = normalizeTreeData(
            categories.response
          );
          const allById = normalizeTreeDataAll(categories.response);
          //aggiungo anche la struttura flat
          _categories.allById = allById.byId ?? {};
          const _tags = normalizeFlatList(tags.response);
          const _properties = normalizeTreeData(properties.response);
          const _language = state$.value.language.selectedLanguage?.code;

          const axs = [actions.initFiltersSuccess(
            _markets,
            _typologies,
            _categories,
            _tags,
            _properties
          )];
          let initFilters =  {};
          let ls = localStorage.getItem("initFilters");
          if (ls) {
            initFilters = JSON.parse(ls);
          }
          let typology = null;
          let typeIds: string[];
          let sub = null
          let selectedTypology = state$.value.filters?.typologies?.selectedTypologies;
          if (selectedTypology && selectedTypology.length > 0) {
            typology = _typologies.byId[selectedTypology[0]];
          } else if (initFilters?.typology && initFilters.typology.length > 0) {
            typology = _typologies.byId[initFilters.typology[0]];
          }
          let selectedSubTypology = state$.value.filters?.typologies?.selectedSubtypology;
          if (typology){
            if(typeof typology == "object" && typology?.id){
              typeIds = [typology.id];
            }
            if(typeof typology == "string"){
              typeIds = [typology];
            }
            axs.push(actions.initTypology(typeIds));
            if(!selectedSubTypology && initFilters?.subTypology) {
                selectedSubTypology = initFilters.subTypology;
            }
            if(selectedSubTypology){
              let selectedSubtyplogyIds = [];
              if(typeof selectedSubTypology == "object" && selectedSubTypology?.id){
                selectedSubtyplogyIds = [selectedSubTypology.id];
              }
              if(typeof selectedSubTypology == "string"){
                selectedSubtyplogyIds = [selectedSubTypology];
              }
              sub = findSubtypeById(typology, selectedSubtyplogyIds[0]);
              if (sub) {
                axs.push(actions.initSubTypology(sub));
              }
            }
          }

          // console.log("markets ", _markets);
          //let ls = localStorage.getItem("initFilters");
          /*if (ls) {
            const initFilters = JSON.parse(ls);*/
          localStorage.removeItem("initFilters");
          /*const validMarketIds = (initFilters.market || []).filter((marketId: string) => _markets.byId[marketId]);
          validMarketIds.length && axs.push(actions.initMarket(validMarketIds));
          const type = _typologies.byId[initFilters.typology[0]];
          type && axs.push(actions.initTypology(initFilters.typology))
          if (type && initFilters.subTypology) {
            const sub = findSubtypeById(type, initFilters.subTypology)
            sub && axs.push(actions.initSubTypology(sub))
          }
          if (!!initFilters?.search) {
            axs.push(actions.initSearchString(initFilters?.search))
          }
        }*/
          axs.push(actions.getFiltersSuccess(
            _markets,
            _typologies,
            _categories,
            _tags,
            _properties
          ));
          return of(...axs);
        }),
        catchError((error) => {
          if (error.response)
            return of(
              actions.getFiltersError(error.response.message, error.status)
            );
          return of(actions.getFiltersError(error.message, error.status));
        })
      );
    })
  );
